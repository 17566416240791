<template>
  <div>
    <div id="model1">
      <a-modal
        :visible="visible2"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <div slot="title" style="width: 100%">
          报警照片（{{ this.childValue.ws_name }}）
        </div>
        <viewer>
          <div class="picture_wrap">
            <div class="pic">
              <img
                :src="this.childValue.e_img"
                :id="this.childValue.e_img"
                @click="fangdaImg()"
              />
            </div>
          </div>
        </viewer>

        <div>
          <div class="biaoti">
            <div class="biaoti1">报警事件</div>
            <div class="biaoti1 jiuzheng">
              <div><span>*</span>纠正</div>

              <!-- <div><a>查看纠正日志</a></div> -->
            </div>
          </div>
          <div class="neirong">
            <div class="left">
              <div>
                {{ childValue.e_events }} （{{ childValue.e_score }}
                ）
              </div>
            </div>
            <div class="right">
              <div>
                <a-radio-group
                  name="radioGroup"
                  @change="changeradioValue"
                  v-model="radioValue"
                >
                  <a-radio :value="1">判断正确</a-radio>
                  <a-radio :value="2">判断错误</a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="last">
              <a-radio-group name="radioGroup" :default-value="1">
                <a-radio :value="YiShenHe" disabled
                  ><span>{{
                    childValue.s_process == "1" ? "未审核" : "已审核"
                  }}</span></a-radio
                >
              </a-radio-group>
              *纠正后，数据实时生效，之后不可恢复
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { GetPickup } from "@/api/image";
export default {
  components: {},
  props: {
    childValue: {
      type: Object,
      default: {},
    },
    visible2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ModalText: "报警图片",
      confirmLoading: false,
      // value: this.text,
      editable: false,
      visible: "",
      value: "",
      YiShenHe: 1,
      queryParam: {
        // ws_id: "1532", //工地id  s_ws_id
        // s_id: "12022", //快照id s_id
        // si_type: "5", //纠正类型
        // si_result: "2", //快照修改后结果
      },
      si_type: "",
      si_result: "",
      radioValue: 1,
      old_result: "",
    };
  },

  created() {
    document.body.classList.add("my_img_viewer_body");
    // console.log(this.childValue);
    // old_result 未穿工服2 有人吸烟1 穿工服1
    if (this.childValue.e_events != undefined) {
      if (this.childValue.e_events == "未穿工服") {
        this.si_type = "5"; //clothes 是否穿工装
        this.old_result = "2";
        this.si_result = "2";
      } else if (this.childValue.e_events == "有人吸烟") {
        this.si_type = "6"; //抽烟
        this.old_result = "1";
        this.si_result = "1";
      } else if (this.childValue.e_events == "穿工服") {
        this.si_type = "5"; //clothes 是否穿工装
        this.old_result = "1";
        this.si_result = "1";
      }
    }
  },

  updated() {},

  methods: {
    loadData1(parameter) {
      this.queryParam = {
        ws_id: this.childValue.s_ws_id, //工地id  s_ws_id
        s_id: this.childValue.s_id, //快照id s_id
        si_type: this.si_type, //纠正类型
        si_result: this.si_result, //快照修改后结果
        old_result: this.old_result, //快照修改前
      };
      const param = Object.assign({}, parameter, this.queryParam);
      const orderParam = [
        "ws_id",
        "s_id",
        "si_type",
        "si_result",
        "old_result",
      ];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPickup(params).then((res) => {
        console.log(res);
        if (res.data != "必填字段为空，请确认后重传") {
          this.$message.success(res.data);
        }
      });
    },
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      // setTimeout(() => {
      this.visible = false;
      this.$emit("jiuzheng", this.visible);
      this.confirmLoading = false;
      this.loadData1(); //点击确定，调接口GetPickup
      // }, 500);
    },

    handleCancel(e) {
      console.log("Clicked cancel button");
      this.visible = false;
      this.$emit("jiuzheng", this.visible);
    },

    changeradioValue(e) {
      // console.log("1判断正确;2判断错误", e.target.value);
      let events = this.childValue.e_events;
      // console.log(events);
      if (events == "未穿工服") {
        if (e.target.value == "1") {
          this.si_result = "2";
        } else if (e.target.value == "2") {
          this.si_result = "1";
        }
      } else if (events == "穿工服" || events == "有人吸烟") {
        this.si_result = e.target.value;
      }
      console.log("this.si_result", this.si_result);
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-modal {
  width: 787px;
  height: 75%;
  top: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}
/deep/.ant-modal-content {
  width: 1080px;
  // height: 80%;
  position: absolute;
  // top: 5%;
}
/deep/.ant-modal-body {
  // height: 730px;
  height: 800px;
}
.biaoti {
  margin-top: 20px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  // border: solid 1px black;
  background-color: #f0f1f4;
  text-align: center;
  line-height: 40px;
  .biaoti1 {
    width: 50%;
    border: solid 1px black;
  }
  .jiuzheng {
    border-left: none;
    display: flex;
    justify-content: center;
    span {
      color: red;
      // margin-left: 220px;
      font-size: 18px;
    }
    a {
      margin-left: 120px;
    }
  }
}
.neirong {
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .left,
  .right {
    width: 50%;
    border: solid 1px black;
    border-top: none;
  }

  .right {
    border-left: none;
  }
  .last {
    // background-color: pink;
    margin-top: 10px;
    width: 100%;
    text-align: right;
  }
}

.picture_wrap {
  margin: 0 10px 10px 10px;
  border-radius: 4px;
  display: flex;
}
.pic {
  min-width: 100px;
  min-height: 100px;
  width: 30%;
  margin-right: 1%;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  img {
    width: 1000px;
    height: auto;
  }
}
.my_img_viewer_body {
  overflow: hidden;
}
</style>
