<template>
  <div>
    <div id="model1">
      <a-modal
        :visible="visible3"
        :confirm-loading="confirmLoading"
        :cancel-button-props="{ style: { display: 'none' } }"
        :ok-button-props="{ style: { display: 'none' } }"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <div slot="title" style="width: 100%">
          报警照片 （{{ this.childValuePl[item].ws_name }}）
        </div>
        <!-- 报警照片 -->
        <viewer>
          <div class="picture_wrap">
            <div class="pic">
              <img :src="this.childValuePl[item].e_img" alt="" />
            </div>
          </div>
        </viewer>
        <div>
          <div class="biaoti">
            <div class="biaoti1">报警事件</div>
            <div class="biaoti1 jiuzheng">
              <div><span>*</span>纠正</div>
              <!-- <div><a>查看纠正日志</a></div> -->
            </div>
          </div>
          <div class="neirong">
            <div class="left">
              <div
                class="chouyan"
                v-show="childValuePl[item].e_events.indexOf('吸烟') != '-1'"
              >
                有人吸烟 （
                {{ childValuePl[item].e_score }}

                ）
              </div>
              <div
                class="chouyan gongzhuang"
                v-show="childValuePl[item].e_events.indexOf('工') != '-1'"
              >
                未穿工服 （
                {{ childValuePl[item].e_score }}

                ）
              </div>
            </div>
            <div class="right">
              <div
                class="chouyan"
                v-show="childValuePl[item].e_events.indexOf('吸烟') != '-1'"
              >
                <a-radio-group
                  name="radioGroup"
                  @change="changeradioValue"
                  :value="
                    childValuePl[item].e_events == '有人吸烟'
                      ? '判断正确'
                      : '判断错误'
                  "
                >
                  <a-radio :value="smoking">判断正确</a-radio>
                  <a-radio :value="nosmoking">判断错误</a-radio>
                </a-radio-group>
              </div>
              <div
                class="gongzhuang"
                v-show="childValuePl[item].e_events.indexOf('工') != '-1'"
              >
                <a-radio-group
                  name="radioGroup "
                  @change="changeradioValue2"
                  :value="
                    childValuePl[item].e_events == '未穿工服'
                      ? '判断正确'
                      : '判断错误'
                  "
                >
                  <a-radio :value="Noclothes">判断正确</a-radio>
                  <a-radio :value="clothes">判断错误</a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
        </div>

        <!-- 上一条下一条按钮 -->
        <div class="BTN">
          <div><a-button @click="back()">上一条</a-button></div>
          <div>
            <span> *纠正后，数据实时生效，之后不可恢复</span>
            <span class="BTN_right">
              <a-button @click="TG_next()">跳过，下一条</a-button>
              <a-button @click="YSH_next()" type="primary">
                已审核，下一条
              </a-button>
            </span>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { GetPickup, GetAlarmEvent } from "@/api/image";

const orderParam = [
  "bc_name",
  "ws_name",
  "e_events",
  "eventworker_type",
  "eventworker_post",
  "e_time_start",
  "e_time_end",
  "pageNo",
  "pageSize",
];
export default {
  components: {},
  props: {
    childValuePl: {
      type: Array,
      default: [],
    },
    visible3: {
      type: Boolean,
      default: false,
    },
    queryParam: {
      type: Object,
      default: {
        bc_name: "",
        ws_name: "",
        e_events: "",
        e_time_start: "",
        e_time_end: "",
        eventworker_type: "1",
      },
    },
  },
  data() {
    return {
      ModalText: "报警图片",
      confirmLoading: false,
      editable: false,
      visible: "",
      YiShenHe: 1,
      value: "",
      item: 0,
      Noclothes: "判断正确",
      clothes: "判断错误",
      smoking: "判断正确",
      nosmoking: "判断错误",
      queryParamJZ: {
        // ws_id: "1532", //工地id  s_ws_id
        // s_id: "12022", //快照id s_id
        // si_type: "5", //纠正类型
        // si_result: "2", //快照修改后结果
      },
      si_type: "",
      si_result: "2",
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // childValuePl: [],
    };
  },
  beforeCreate() {
    // this.loadDataPL;
    console.log("111");
  },
  created() {
    // console.log(this.childValuePl[this.item].e_events.indexOf("工"));
    this.loadDataPL;
    // console.log("111");
  },
  mounted() {
    // console.log(this.childValuePl);
    if (this.childValuePl[this.item].e_events.indexOf("工") != "-1") {
      this.si_type = "5"; //clothes 是否穿工装
    } else if (this.childValuePl[this.item].e_events.indexOf("吸烟") != "-1") {
      this.si_type = "6"; //抽烟
    }
    console.log("5工装,6抽烟", this.si_type);
  },

  updated() {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    // loadDataPL(parameter) {
    //   this.pageNo = parameter.pageNo;
    //   this.pageSize = parameter.pageSize;
    //   const param = Object.assign({}, parameter, this.queryParam);
    //   const requestParameters = this.$order(param, orderParam);
    //   const params = {
    //     ...requestParameters,
    //     verify: this.$verify(requestParameters, orderParam),
    //   };
    //   return GetAlarmEvent(params).then((res) => {
    //     res.data.pageNo = res.data.page;
    //     res.data.totalCount = res.data.sum;
    //     // 处理数据
    //     // const url = this.pathUrl;
    //     let data = res.data.data;
    //     console.log(data);
    //     this.childValuePl = data;
    //     // data.forEach((el, i) => {
    //     //   let img = el.e_img;
    //     //   el.e_img = url + img;
    //     // });
    //     // return res.data;
    //   });
    // },

    // 获取纠正接口数据
    loadData(parameter) {
      this.queryParamJZ = {
        ws_id: this.childValuePl[this.item].s_ws_id, //工地id  s_ws_id
        s_id: this.childValuePl[this.item].s_id, //快照id s_id
        si_type: this.si_type, //纠正类型
        si_result: this.si_result, //快照修改后结果
      };
      const param = Object.assign({}, parameter, this.queryParamJZ);
      const orderParam = ["ws_id", "s_id", "si_type", "si_result"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPickup(params).then((res) => {
        console.log(res);
        if (res.data != "必填字段为空，请确认后重传") {
          this.$message.success(res.data);
        }
      });
    },
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      console.log("handleok");
      // setTimeout(() => {
      // this.visible = this.visible2;
      this.visible = false;
      this.$emit("JiuzhengPl", this.visible);
      this.confirmLoading = false;
      // }, 500);
    },

    handleCancel(e) {
      console.log("Clicked cancel button");
      // this.visible = this.visible2;
      this.item = 0;
      this.visible = false;

      this.$emit("JiuzhengPl", this.visible);
    },

    changeradioValue(e) {
      console.log("111", e.target.value);
      if (e.target.value == "判断错误") {
        this.si_result = "2";
        this.childValuePl[this.item].e_events = "无人吸烟";
      } else if (e.target.value == "判断正确") {
        this.si_result = "1";
        this.childValuePl[this.item].e_events = "有人吸烟";
      }
      console.log(this.si_result);
      // this.childValue.e_events = e.target.value;
    },

    changeradioValue2(e) {
      console.log("111", e.target.value, this.childValuePl[this.item].e_events);
      this.childValuePl[this.item].e_events = e.target.value;
      if (e.target.value == "判断正确") {
        this.si_result = "2";
        this.childValuePl[this.item].e_events = "未穿工服";
      } else if (e.target.value == "判断错误") {
        this.si_result = "1";
        this.childValuePl[this.item].e_events = "已穿工装";
      }

      console.log("this.si_result", this.si_result);
      // this.childValue.e_events = e.target.value;
      // this.childValue.e_events.trim().split(" ")[0] = e.target.value;
    },
    back() {
      console.log("点击了上一条");
      if (this.item == 0) {
        // alert("已是此页第一条");
        this.$message.warning("已是此页第一条");
        this.item = 0;
      } else {
        this.item = parseInt(this.item - 1);
      }
    },
    TG_next() {
      // console.log("点击了跳过，下一条");
      if (this.item == parseInt(this.childValuePl.length - 1)) {
        // alert("已是此页最后一条");
        this.$message.warning("已是此页最后一条");
        this.item = parseInt(this.childValuePl.length - 1);
        this.pageNo = parseInt(this.pageNo + 1);
        console.log("this.pageNo", this.pageNo);
      } else {
        this.item = parseInt(this.item + 1);
      }
    },
    YSH_next() {
      console.log("点击了已审核，下一条");
      this.loadData();
      if (this.item == parseInt(this.childValuePl.length - 1)) {
        // alert("已是此页最后一条");
        this.$message.warning("已是此页最后一条");
        this.item = parseInt(parseInt(this.childValuePl.length - 1));
      } else {
        this.item = parseInt(this.item + 1);
      }
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-modal {
  // width: 787px;
  // width: 900px;
  height: 50%;
  top: 1%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 60px;
}
/deep/.ant-modal-content {
  width: 970px;
  // height: 80%;
  position: absolute;
  // top: 5%;
}
/deep/.ant-modal-body {
  // height: 625px;
  height: 730px;
}

.picture_wrap {
  margin: 0 10px 10px 10px;
  border-radius: 4px;
  display: flex;
}
.pic {
  min-width: 100px;
  min-height: 100px;
  width: 30%;
  margin-right: 1%;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  img {
    width: 900px;
    // height: 600px;
    height: auto;
    margin: auto;
  }
}
.BTN {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  .BTN_right {
    .ant-btn {
      margin-left: 10px;
    }
  }
}
.biaoti {
  margin-top: 20px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  // border: solid 1px black;
  background-color: #f0f1f4;
  text-align: center;
  line-height: 40px;
  .biaoti1 {
    width: 50%;
    border: solid 1px black;
  }
  .jiuzheng {
    border-left: none;
    display: flex;
    justify-content: center;
    span {
      color: red;
      // margin-left: 100px;
      font-size: 18px;
    }
    a {
      margin-left: 90px;
    }
  }
}
.neirong {
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .left,
  .right {
    width: 50%;
    border: solid 1px black;
    border-top: none;
  }
  .right {
    border-left: none;
  }
  .last {
    // background-color: pink;
    margin-top: 10px;
    width: 100%;
    text-align: right;
  }
}
// .gongzhuang {
//   // border-top: 1px solid black;
// }
</style>
