<template>
  <div id="AlarmEventID">
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      :AlarmEvent="AlarmEvent"
      :Attention_bc_name="Attention_bc_name"
      ref="SSearch"
    ></s-search>

    <div class="table-button">
      <a-button
        type="primary"
        @click="PiLiangSHZP"
        v-if="isShowBtns.indexOf('AlarmEvent-/api/snapshot/pickup') > -1"
        >批量审核照片</a-button
      >
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="isShowBtns.indexOf('AlarmEvent-/api/worker/eventexport') > -1"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="AlarmEvent"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <!--:scroll="{ x: 2000 }"     :scroll="{ x: 1700, y: 'calc(853px - 105px )' }"-->
    <s-table
      ref="table"
      size="small"
      rowKey="serial"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1700, y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      @change="changeTable"
    >
      <!-- :scroll="{ x: 1600 }" -->
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <!-- 报警事件 -->
      <span slot="e_events" slot-scope="text">
        {{ text }}
      </span>
      <!-- 工地名称 -->
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span @click="jumpToSiteList(text)" class="cursor_pointer">{{
            text ? text.replace(/直辖市|-北京|-天津/g, "") : text
          }}</span>
        </a-tooltip>
      </span>
      <!-- 照片 -->
      <span slot="e_img" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record)">
          <div class="actions">
            <div class="icon_out">
              <img
                :src="text"
                width="100%"
                height="100%"
                class="imgname"
                href="javascript:;"
              />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
          <div>
            {{ record.d_imei }}
          </div>
        </span>
      </span>
      <span slot="e_time" slot-scope="text">
        {{ $Format(text) }}
      </span>
      <!-- s_process -->
      <span slot="s_process" slot-scope="text">
        {{ text == "1" ? "未审核" : "已审核" }}
      </span>
      <span slot="si_correct" slot-scope="text">
        {{ text | SitCorrectStatus }}
      </span>
      <span slot="si_eventworker_w_post" slot-scope="text">
        {{ text }}
      </span>
      <!-- <span slot="si_eventworker" slot-scope="text, record">
        {{ text }}
        <p>{{ record.w_post }}</p>
      </span> -->
      <span slot="w_post" slot-scope="text">
        {{ text }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <a
          @click="OpenDrawer('SitePreview', '直播', record)"
          class="OpenDrawerPreview"
          v-if="
            isShowBtns.indexOf('AlarmEvent-/api/worksite/camera/video') > -1
          "
          >直播</a
        >
        <a
          @click="handele(text, record, index)"
          href="javascript:;"
          v-if="isShowBtns.indexOf('AlarmEvent-/api/snapshot/pickup') > -1"
          >纠正</a
        >
      </span>
    </s-table>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :loading="loading"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>

    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">
        报警照片（{{ ImgName }}）报警日期：<span style="color: red">{{
          Imgdate
        }}</span>
      </div>
      <div v-if="Image.length > 77" style="width: 100%" id="Image">
        <img :src="Image" width="100%" href="javascript:;" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image)">下载图片</div>
        </div>
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
    <a-modal
      width="0px"
      :footer="null"
      :visible="visibleJZ"
      @cancel="JZCancel"
      :closable="false"
    >
      <jiu-zheng
        :visible2="visible2"
        @jiuzheng="jiuzheng"
        :childValue="childValue"
        v-if="visible2"
      />
      <!-- :overflowBackup="overflowBackup" -->

      <jiuzheng-pl
        :visible3="visible3"
        @JiuzhengPl="JiuzhengPl"
        :queryParam="queryParam"
        :childValuePl="childValuePl"
        v-if="visible3"
      ></jiuzheng-pl>
    </a-modal>
    <a-drawer
      :title="BasicDrawerName"
      placement="right"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      :destroyOnClose="true"
      @close="onClose"
      width="800px"
    >
      <SitePreview
        :record="record"
        v-if="SitePreview"
        ref="SitePreview"
      ></SitePreview>
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetAlarmEvent } from "@/api/image";
import { ExportEvent } from "@/api/export";
import { urlConfig } from "@/config/config";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetCompanies } from "@/api/device";

import JiuZheng from "./JiuZheng.vue";
import JiuzhengPl from "./JiuzhengPl.vue";
import moment from "moment";
import SitePreview from "../site/SitePreview.vue";

// 搜索条件
const queryItems = [
  {
    label: "人员识别姓名",
    value: "w_event_name",
    type: "input",
    defaultValue: "",
  },
  { label: "工地名称", value: "ws_name", type: "input" },
  // { label: "分公司名称", value: "bc_name", type: "input" },
  {
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "报警事件",
    value: "e_events",
    type: "select",
    selectOption: "AlarmEvents",
    defaultValue: "",
  },

  {
    label: "人员识别",
    value: "eventworker_type",
    type: "select",
    selectOption: "SiteEventworkerType",
    defaultValue: "1",
  },
  {
    label: "岗位",
    value: "eventworker_post",
    type: "select",
    selectOption: "WorkPost",
  },
  // 新增
  {
    label: "工地所属项目经理",
    value: "ws_jl_name",
    type: "input",
    defaultValue: "",
  },
  {
    label: "工地所属工程管家",
    value: "ws_gj_name",
    type: "input",
    defaultValue: "",
  },
  // {
  //   label: "设备所属组织",
  //   // value: "eventworker_post",
  //   type: "select",
  //   // selectOption: "WorkPost",
  // },
  {
    label: "报警事件日期查询",
    value: "e_time",
    type: "dateRange",
    range: ["e_time_start", "e_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
];
const orderParam = [
  "bc_name",
  "ws_name",
  "e_events",
  "eventworker_type",
  "eventworker_post",
  "ws_gj_name",
  "ws_jl_name",
  "e_time_start",
  "e_time_end",
  "w_event_name",
  "pageNo",
  "pageSize",
];

const columns = [
  {
    title: "序号",
    width: "80px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },

  {
    title: "报警事件",
    width: "100px",
    dataIndex: "e_events",
    scopedSlots: { customRender: "e_events" },
    align: "center",
  },
  {
    title: "报警照片",
    width: "180px",
    dataIndex: "e_img",
    scopedSlots: { customRender: "e_img" },
  },
  {
    title: "报警时间",
    width: "120px",
    dataIndex: "e_time",
    scopedSlots: { customRender: "e_time" },
    align: "center",
  },
  {
    title: "人员识别",
    width: "100px",
    dataIndex: "si_eventworker",
    // scopedSlots: { customRender: "si_eventworker" },
    align: "center",
  },
  {
    title: "岗位/工种",
    width: "100px",
    // dataIndex: "si_eventworker",
    dataIndex: "w_post",
    scopedSlots: { customRender: "si_eventworker_w_post" },
    align: "center",
  },
  // {
  //   title: "gangwei",
  //   width: "100px",
  //   dataIndex: "w_post",
  //   scopedSlots: { customRender: "si_eventworker" },
  //   align: "center",
  // },
  {
    title: "工地所属分公司",
    width: "140px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "工地管家",
    width: "100px",
    dataIndex: "w_name_gj",
    align: "center",
  },
  {
    title: "项目经理",
    width: "100px",
    dataIndex: "w_name_jl",
    align: "center",
  },
  {
    title: "工地名称",
    // width: "250px",
    dataIndex: "ws_name",
    /**
     * 如果要自定义该列表格内容的显示，需要写scopedSlots
     * 具体自定义内容见template中<s-table>的写法
     */
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  // {
  //   title: "事件分数",
  //   width: "100px",
  //   dataIndex: "e_score",
  //   scopedSlots: { customRender: "e_score" },
  //   align: "center",
  // },
  // 新增
  // {
  //   title: "审核状态",
  //   width: "50px",
  //   dataIndex: "s_process",
  //   scopedSlots: { customRender: "s_process" },
  //   align: "center",
  // },
  // {
  //   title: "纠正状态",
  //   width: "50px",
  //   dataIndex: "si_correct",
  //   scopedSlots: { customRender: "si_correct" },
  //   align: "center",
  // },
  {
    title: "操作",
    width: "100px",
    scopedSlots: { customRender: "action" },
    dataIndex: "action",
    align: "center",
    fixed: "right",
    ellipsis: true,
  },
];

export default {
  name: "AlarmEvent",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    JiuZheng,
    JiuzhengPl,
    SitePreview,
  },

  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        bc_name: "",
        ws_name: "",
        e_events: "",
        e_time_start: "",
        e_time_end: "",
        eventworker_type: "1",
        eventworker_post: "",
        ws_jl_name: "",
        ws_gj_name: "",
      },
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      visible: false,
      confirmLoading: false,
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // 查看图片
      visibleImg: false,
      Image: "",
      ImgName: "",
      Imgdate: "",
      childValue: {},
      childValuePl: [],
      visible2: false,
      visible3: false,
      overflowBackup: "",
      AlarmEvent: "",
      e_time: {
        e_time_start: "",
        e_time_end: "",
      },
      Attention_bc_name: "",
      loading: false,
      visibleDrawer: false,
      BasicDrawerName: "",
      SitePreview: false,
      visibleJZ: false,
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // 默认值为七天前的日期和今天的日期
    var sevenTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
    var sevenTime = new Date().getTime();
    // let date = this.$Format(Math.round(sevenTime / 1000));
    // console.log(date);
    this.queryItems[8].defaultValue = [
      moment(sevenTime),
      moment(moment(new Date())),
    ];
    this.queryParam.e_time_start = Math.round(sevenTime / 1000);
    this.queryParam.e_time_end = Math.round(new Date().getTime() / 1000);
    // console.log("this.$route.params", this.$route.params);
    if (this.queryItems.some((el) => el.selectOption == "CompanyList")) {
      GetCompanies().then((res) => {
        // console.log(res);
        this.filters.CompanyList.length = 0;
        this.filters.CompanyList.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyList.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }
    if (this.$route.params.e_events) {
      setTimeout(() => {
        this.$refs.SSearch.expandChange();
      }, 10);
      //报警事件
      this.AlarmEvent = this.$route.params.e_events;
      this.queryItems[3].defaultValue = this.$route.params.e_events;
      this.queryParam.e_events = this.$route.params.e_events;
      this.Attention_bc_name = this.$route.params.bc_id;
      this.queryParam.bc_name = this.$route.params.bc_id;
      // 是否为员工
      this.queryItems[4].defaultValue = this.$route.params.si_eventworker;
      this.queryParam.eventworker_type = this.$route.params.si_eventworker;
    } else {
      this.AlarmEvent = "";
      this.Attention_bc_name = "";
    }
    if (this.$route.params.e_time_start) {
      // setTimeout(() => {
      //   this.$refs.SSearch.expandChange();
      // }, 10);
      this.queryParam.e_time_start = this.$route.params.e_time_start;
      this.queryParam.e_time_end = this.$route.params.e_time_end;
      this.queryItems[8].defaultValue = [
        moment(this.$Format(this.$route.params.e_time_start)),
        moment(this.$Format(this.$route.params.e_time_end)),
      ];
      // console.log("111111", this.$Format(this.$route.params.e_time_start));
    } else {
      // 默认值为七天前的日期和今天的日期
      // var sevenTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
      // var sevenTime = new Date().getTime();
      // // let date = this.$Format(Math.round(sevenTime / 1000));
      // // console.log(date);
      // this.queryItems[8].defaultValue = [
      //   moment(sevenTime),
      //   moment(moment(new Date())),
      // ];
      // this.queryParam.e_time_start = Math.round(sevenTime / 1000);
      // this.queryParam.e_time_end = Math.round(new Date().getTime() / 1000);
    }
    if (this.$route.query.e_events) {
      console.log(this.$route.query);
      //报警事件
      // this.queryItems[3].defaultValue = this.$route.query.e_events;
      // this.queryParam.e_events = this.$route.query.e_events;
      // 是否为员工
      this.queryItems[4].defaultValue = this.$route.query.si_eventworker;
      this.queryParam.eventworker_type = this.$route.query.si_eventworker;
      // console.log(this.queryItems[4].defaultValue);
      // 分公司名称
      this.AlarmEvent = this.$route.query.e_events;
      this.Attention_bc_name = this.$route.query.bc_id;
      this.queryParam.bc_name = this.$route.query.bc_id;
      // 工地名称
      //  this.queryItems[1].defaultValue = this.$route.query.ws_name;
      // this.queryParam.ws_name = this.$route.query.ws_name;
      // 默认值的改变
      for (let item in this.$route.query) {
        // 默认值的改变
        this.queryItems.forEach((el) => {
          if (el.value == item) {
            el.defaultValue = this.$route.query[item];
          }
        });
        // 查询值的改变
        for (let index in this.queryParam) {
          // console.log(this.queryParam);
          if (index == item) {
            this.queryParam[index] = this.$route.query[item];
          }
          // console.log(this.queryParam[index]);
        }
      }
    }
    // 如果query中的时间不为空的话执行
    if (this.$route.query.e_time_start) {
      this.queryParam.e_time_start = this.$route.query.e_time_start;
      this.queryParam.e_time_end = this.$route.query.e_time_end;
      this.queryItems[8].defaultValue = [
        moment(this.$Format(this.$route.query.e_time_start)),
        moment(this.$Format(this.$route.query.e_time_end)),
      ];
    } else {
      // console.log("2222222");
      // 默认值为当天的日期
      // var sevenTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
      // var sevenTime = new Date().getTime();
      // this.queryItems[8].defaultValue = [
      //   moment(sevenTime),
      //   moment(moment(new Date())),
      // ];
      // this.queryParam.e_time_start = Math.round(sevenTime / 1000);
      // this.queryParam.e_time_end = Math.round(new Date().getTime() / 1000);
    }
  },
  // 纠正弹窗关闭，刷新表格
  watch: {
    visible2(NValue, OValue) {
      // console.log(NValue, OValue);
      //监听弹窗关闭 如果弹窗关闭，就调接口
      if (NValue == false) {
        this.$refs.table.loadData(); //刷新当前页表格
        this.visibleJZ = false;
      }
    },
    visible3(NValue, OValue) {
      if (NValue == false) {
        this.$refs.table.loadData(); //刷新当前页表格
        this.visibleJZ = false;
      }
    },
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
  },
  mounted() {
    // this.loadData();
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("AlarmEventColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.pageNo = parameter.pageNo;
        this.pageSize = parameter.pageSize;
      } else {
      }
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        // ws_status: "",
        // d_status: "",
        // ws_bind_status: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetAlarmEvent(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        // 处理数据
        const url = this.pathUrl;
        let data = res.data.data;
        // console.log(data);
        this.childValuePl = data;
        data.forEach((el, i) => {
          let img = el.e_img;
          el.e_img = url + img;
        });
        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      // console.log(this.queryParam);
      this.$refs.table.refresh(true);
    },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
    exportOk() {
      this.confirmLoading = true;
      const params = {
        bc_name: this.queryParam.bc_name,
        ws_name: this.queryParam.ws_name,
        e_events: this.queryParam.e_events,
        e_time_start: this.queryParam.e_time_start,
        e_time_end: this.queryParam.e_time_end,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        eventworker_type: this.queryParam.eventworker_type,
        eventworker_post: this.queryParam.eventworker_post,
        ws_gj_name: this.queryParam.ws_gj_name,
        ws_jl_name: this.queryParam.ws_jl_name,
      };
      ExportEvent(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "报警事件列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visible = false;
        this.confirmLoading = false;
      });
    },
    exportCancel() {
      this.visible = false;
    },
    showInfo(url, record) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = record.ws_name;
      this.Imgdate = this.$Format(record.e_time);
      let that = this;
      that.Image = url;
      // requestImage(this.pathUrl + url,function(url){
      //   that.Image = url
      // })
      // document.body.classList.add("my_img_viewer_body");
    },
    imgCancel() {
      this.visibleImg = false;
      // document.body.classList.add("my_img_viewer_body");
      // document.body.classList.remove("my_img_viewer_body");
    },

    handele(text, record, index) {
      console.log("纠正");
      // console.log(record);
      this.childValue = record;
      // console.log(this.childValue);
      this.visibleJZ = true;
      this.visible2 = true;
      // console.log(this.visible2);
    },
    jiuzheng(visible) {
      this.visible2 = visible;
    },
    JiuzhengPl(visible) {
      this.visible3 = visible;
    },
    PiLiangSHZP() {
      console.log("点击了批量审核照片");
      // console.log(this.childValuePl);
      this.visibleJZ = true;
      this.visible3 = true;
    },
    JZCancel() {
      this.visibleJZ = false;
    },
    changeTable(pagination, filters, sorter, { currentDataSource }) {
      console.log(pagination, filters, sorter, { currentDataSource });
      // this.loadData();
    },
    // 直播
    OpenDrawer(temp, title, record) {
      console.log(temp, title, record);
      this.visibleDrawer = true;
      this.BasicDrawerName = title;
      this.SitePreview = true;
      let ws_id = { ws_id: record.s_ws_id };
      Object.assign(record, ws_id);
      this.record = record;
    },
    onClose() {
      this.visibleDrawer = false;
      this.SitePreview = false;
    },
    afterVisibleChange(val) {
      // console.log("visibleDrawer", val);
    },
    // 点击工地地址跳转到工地列表页面
    jumpToSiteList(text) {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          ws_name: text,
        },
      });
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
imgname :hover {
  transform: translateY(-30px);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.7);
}

// 鼠标悬停蒙层
.icon_out {
  // width: 209px;
  // width: 221px;
  width: 100%;
  height: 85px;
  position: relative;
  display: flex;
}
.icon {
  font-size: 25px;
  left: 43%;
  top: 40%;
  position: absolute;
  margin: auto;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    // transition: all 0.3s;
    // width: 209px;
    // height: 117px;
    // width: 221px;
    width: 100%;
    height: 85px;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info_out {
  position: relative;
  cursor: pointer;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
// /deep/.ant-table table {
//   text-align: center;
// }
.my_img_viewer_body {
  overflow: hidden;
}
.OpenDrawerPreview {
  margin-right: 10px;
}
.cursor_pointer {
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #1890ff;
}
#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
</style>
